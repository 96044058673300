import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nedtrekkslister",
  "template": "default",
  "introtext": "Dropdown lar brukeren velge mellom ulike skjemadata"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Bruk av nedtrekkslister`}</h2>
    <p>{`Vi har to typer dropdown: en søkbar dropdown og en vanlig dropdown hvor brukeren kan velge mellom ulike skjemadata. Dropdowns kan ofte bli brukt feil, spesielt på mobil kan dropdowns med lange lister og mye scrolling gi dårlig brukeropplevelse. Hvis du er i tvil kan det være en god idé å brukerteste løsningen.`}</p>
    <h2>{`SearchableDropdown`}</h2>
    <p>{`Har du 10 eller flere alternativer i nedtrekkslisten din, anbefaler vi at du bruker nedtrekkslisten med søk. Det er for å gjøre det enda enklere for brukerne å finne det de er på jakt etter. `}</p>
    <DSExample name="dropdown_SearchableDropdown" mdxType="DSExample" />
    <h3>{`Varianter`}</h3>
    <p>{`Hvis du vil vise mer enn en type informasjon, kan du legge det til i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dropdownAttributes`}</code></p>
    <DSExample name="dropdown_SearchableDropdown_2attributes" mdxType="DSExample" />
    <p>{`Du kan også tilpasse listen sånn du vil, ved å sende med et element til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`listElementBody`}</code></p>
    <DSExample name="dropdown_SearchableDropdown_customListElementBody" mdxType="DSExample" />
    <p>{`Vil du lage din egen søkemetode kan du gjøre det også. For eksempel om du vil ignorere mellomrom.`}</p>
    <DSExample name="dropdown_SearchableDropdown_customMatchFunction" mdxType="DSExample" />
    <p>{`Hvis du ikke får noen resultater på søket ditt, men forsatt ønsker å gi brukerne noen forslag, kan du legge til ekstra søkeresultater`}</p>
    <DSExample name="dropdown_SearchableDropdown_extraresults" mdxType="DSExample" />
    <p>{`Vil du bestemme hvilket element som er valgt, kan du bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selectedItem`}</code></p>
    <DSExample name="dropdown_SearchableDropdown_selectedItem" mdxType="DSExample" />
    <p>{`Dersom du ønsker å ha ekstra tekst på bunnen av lista, kan du bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postListElement`}</code></p>
    <DSExample name="dropdown_SearchableDropdown_postListElement" mdxType="DSExample" />
    <h2>{`Dropdown`}</h2>
    <p>{`Dropdown er en vanlig nedtrekksliste som kan brukes man når man har 5-10 valg å velge mellom. Har du mindre enn 5 valg, bruk radio buttons istedenfor. `}</p>
    <DSExample name="dropdown_Dropdown" mdxType="DSExample" />
    <p>{`Dersom skjemaet inneholder valideringsfeil vises det på denne måten:`}</p>
    <DSExample name="dropdown_Dropdown_invalid" mdxType="DSExample" />
    <h2>{`Dropdown multiselect (in development)`}</h2>
    <p>{`For nedtrekkslister der du kan velge å vise flere resultater samtidig (som for eksempel sparekontoer eller aksjefond).`}</p>
    <p>{`I motsetning til reglene for SearchableDropdown og vanlig Dropdown er denne funksjonaliteten brukbar med så lite som to alternativer å velge mellom/kombinere. `}</p>
    <p>{`Dropdown multiselect skal kun brukes i situasjoner det gir mening å gi brukeren mulighet til å markere flere alternativer for sammenligning eller oppsamling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      